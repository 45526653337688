import {Component, Input} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgIf, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    NgTemplateOutlet
  ],
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss'
})
export class LinkComponent {

  @Input() href: string = '';
  @Input() target: string = '_self';
  @Input() style: string = '';
  @Input() title: string = '';
  @Input() active: boolean = false;


  public onClick(): boolean {
    return false;
  }
}
