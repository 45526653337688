<ng-container *ngIf="target === '_self'">
  <a *ngIf="href" [class]="(style === 'nav' ? 'nav-link' : style === 'dropdown' ? 'dropdown-item' : style === 'alert' ? 'alert-link' : '') + (active ? ' active' : '')" [routerLink]="href" [title]="title"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></a>
  <a *ngIf="!href" [class]="(style === 'nav' ? 'nav-link' : style === 'dropdown' ? 'dropdown-item' : style === 'alert' ? 'alert-link' : '') + (active ? ' active' : '')" [href]="'#'" (click)="onClick()" [title]="title"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container></a>
</ng-container>

<ng-container *ngIf="target !== '_self'">
  <a [class]="(style === 'nav' ? 'nav-link' : style === 'dropdown' ? 'dropdown-item' : style === 'alert' ? 'alert-link' : '')" [href]="href" [target]="target" [title]="title" rel="noreferrer"><ng-container *ngTemplateOutlet="linkTemplate"></ng-container> </a>
</ng-container>

<ng-template #linkTemplate><ng-content></ng-content></ng-template>
