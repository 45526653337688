import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-icon-badge',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './icon-badge.component.html',
  styleUrl: './icon-badge.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class IconBadgeComponent {

  @Input() style: 'dashboard'|'dashboardIcons'|'barTop'|'plainIcon'|'plainIconSidePadding'|'notification'|'link' = 'dashboard';
  @Input() orientation: 'bottomRight'|'topRight'|'bottomLeft'|'topLeft' = 'bottomRight';
  @Input() icon: string = '';
  @Input() color: 'default'|'lightpurple'|'pink'|'orange'|'aqua'|'yellow'|'blue'|'darkorange'|'cyan'|'purple'|'darkgreen'|'skyblue'|'red'|'brightgreen'|'asphalt'|'darkblue' = 'default';
  @Input() badge: number = 0;
  @Input() title: string = '';
  @Input() danger: boolean = false;
  @Input() alert: boolean = false;
  @Input() screenReaderText: string = '';
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
}
