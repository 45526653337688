import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-headline',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './headline.component.html',
  styleUrl: './headline.component.scss'
})
export class HeadlineComponent {

  @Input() type: 'notification'|'auth'|'h4'|'h5'|'h6' = 'h5';
  @Input() title: string = '';
  @Input() class: string = '';
}
